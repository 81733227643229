<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col>
          <h1>Archived Customers</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: 'module-customers' }">
              <v-icon left>mdi-emoticon</v-icon>Active Customers
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="archived"
            no-data-text="There are no archived customers"
          >
            <template v-slot:item.actions="{item}">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="activateCustomerDialog=true;activateCustomerData=item"
                  >
                    <v-icon small>mdi-account-check</v-icon>
                  </v-btn>
                </template>
                <span>Reactivate Customer</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="activateCustomerDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Reactivate Customer</v-card-title>
        <v-card-text>Are you sure you want to reactivate {{ activateCustomerData ? activateCustomerData.name : '' }}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="activateCustomerDialog=false">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="activateCustomerLoading"
            @click="reactivateCustomer()"
          >Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableHeaders: [
        { text: "Customer", value: "name" },
        { text: "Website(s)", value: "website" },
        { text: "Details", value: "details" },
        { text: "Actions", value: "actions" }
      ],
      activateCustomerDialog: false,
      activateCustomerLoading: false,
      activateCustomerData: null
    };
  },

  computed: {
    archived() {
      return this.$store.state.customers["archived"];
    }
  },

  methods: {
    reactivateCustomer: function() {
      const appId = this.$route.params.id;
      const id = this.activateCustomerData.id;

      this.activateCustomerLoading = true;

      this.$store
        .dispatch("customers/reactivateCustomer", {
          appId,
          id
        })
        .then(() => {
          this.activateCustomerData = null;
          this.activateCustomerLoading = false;
          this.activateCustomerDialog = false;
        });
    }
  }
};
</script>